.top-nav--wrapper {
    position: fixed;
    width: 100vw;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    border-bottom: 2px solid #ff9419;
    display: block;
    padding: 0;
    background: transparent;
    transition: .5s ease-out all;

    .top-nav--logo {
        display: block;
        height: 15px;
        // IOS offset FIX
        transform: translate(0, 0px);

    }

    .hero--title-wrp {
        padding-top: 118px !important;

        @media screen and (max-width:991px) {
            padding-top: 97px !important;
        }
    }

    .hero--wrp {
        margin-top: 118px !important;

        @media screen and (max-width:991px) {
            margin-top: 97px !important;
        }

        .hero--title-wrp {
            padding-top: 0 !important;
        }
    }

    .top-nav--logo-wrp {
        width: calc(100% - 28px);
        padding: 7px 0;
    }

    .top-nav--contact {
        padding-bottom: 10px;
        width: calc(100% - 28px);

        &.top-nav--link {
            text-align: left;
        }
    }

    .top-nav--hamburger {
        display: block;
        width: 25px;
        position: absolute;
        right: 10px;
        top: 10px;
    }

    .top-nav--hamburger-row {
        display: block;
        width: 100%;
        height: 2px;
        background: #fff;
        margin: 5px 0;
    }

    .top-nav--link,
    .top-nav--link--bold {
        text-align: center;
        font-family: 'Source Sans Pro', sans-serif;
        font-weight: 500;
        font-size: 18px;
        color: #fff;
        padding: 10px 0;

        display: block;
        position: relative;

        &.active,
        &:focus,
        &:active,
        &:hover {
            // color: $clean-white;
            text-decoration: none;
        }
    }

    .top-nav--link--bold {
        font-weight: 700;
    }

    @media screen and (min-width:992px) {
        .top-nav--hamburger {
            display: none;
        }

        .top-nav--link-wrp[style] {
            height: auto !important;
        }
    }

    .top-nav--link-wrp {
        margin-left: 70px;
        width: 100%;

        a {
            font-weight: 400;

            @media screen and (min-width:992px) {
                padding: 0;

                &:not(:last-child) {
                    margin-right: 35px;
                }
            }
        }
    }

    .d-flex {
        display: flex;
        align-items: center;
    }

    .top-nav--head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #FEFDFB;
        padding: 10px 5px;

        @media screen and (min-width:768px) {
            padding: 10px 20px;

        }

        P {
            color: #686664;
            font-size: 14px;
            margin: 0;
            padding-left: 22px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='12.198' viewBox='0 0 16 12.198'%3E%3Cpath id='Icon-check' d='M10.2,18.009,6.407,14.216,5.115,15.5,10.2,20.583,21.115,9.668,19.832,8.385Z' transform='translate(-5.115 -8.385)' fill='%23ffa43b'/%3E%3C/svg%3E");
            background-size: 15px;
            background-repeat: no-repeat;
            background-position: left center;
        }

        span {
            font-size: 14px;
            color: #fff;
            display: block;
            margin: auto;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            line-height: 1.2;

            @media screen and (min-width:992px) {
                display: none;
            }
        }
    }

    .top-nav--navigation {
        background: $crew-range-085;
        padding: 7px 5px;
        line-height: 1;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        justify-content: space-between;
        border: 0;
        padding-left: 5px;
        padding-right: 5px;
        position: relative;

        @media screen and (min-width:768px) {
            padding: 18px 18px;
        }

        &__left {
            display: flex;
            align-items: center;
            transition: .5s ease all;
            -webkit-transition: .5s ease all;
            -moz-transition: .5s ease all;
            -ms-transition: .5s ease all;
            -o-transition: .5s ease all;

            a {
                color: #fff;
                font-size: 18px;
                padding: 0;
                display: flex;
                align-items: center;
                transition: .5s ease all;
                -webkit-transition: .5s ease all;
                -moz-transition: .5s ease all;
                -ms-transition: .5s ease all;
                -o-transition: .5s ease all;

                img {
                    margin-right: 10px;
                }

                &:not(:last-child) {
                    margin-right: 18px;
                }

            }
        }
    }

    .d-none {
        display: none !important;
    }

    .button--solid--cta--block {
        background: #057FB3;
        border-radius: 4px;
        box-shadow: none;
        font-size: 16px;
        font-weight: 600;
        padding: 10px 20px !important;
        width: auto;

        //animation: pulse 1.5s infinite;
        //-webkit-animation: pulse 1.5s infinite;
        &:hover {
            background: #11198acc;
        }
    }

    &.sticky {
        transform: translateY(-40px);
    }

    @media screen and (min-width:992px) {
        &.sticky {
            #topnav-login {
                display: none;
            }
        }

        .top-nav--link--animation {
            &:after {
                content: "";
                position: absolute;
                display: block;
                width: 0%;
                // background: $clean-white;
                height: 2px;
                bottom: -4px;
                left: 0;
                transition: .4s ease all;
                -webkit-transition: .4s ease all;
                -moz-transition: .4s ease all;
                -ms-transition: .4s ease all;
                -o-transition: .4s ease all;
                transform: none;
            }

            &:hover:after,
            &.active:after {
                width: 100%;
            }
        }

        .top-nav--wrp {
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: justify;
            -webkit-justify-content: space-between;
            -ms-flex-pack: justify;
            justify-content: space-between;
            padding: 20px 20px 17px;
            -webkit-flex-wrap: nowrap;
            -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
        }

        .top-nav--contact {
            width: 40%;
            min-width: 320px;
            padding-bottom: 0;
        }

        .top-nav--logo-wrp {
            height: 18px;
            width: auto;
            transform: translate(0, 1px);
            padding: 0;
        }

        .top-nav--logo {
            width: auto;
        }

        .top-nav--link,
        .top-nav--link--bold {
            text-align: left;
            padding: 0 3px;
        }

        .top-nav--link-wrp {
            padding-top: 0;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: justify;
            -webkit-justify-content: space-between;
            -ms-flex-pack: justify;
            justify-content: space-between;
            overflow: visible;

        }
    }

    @media screen and (max-width:1300px) {
        .top-nav--link-wrp {
            margin-left: 30px;

            a {
                font-size: 16px;
            }
        }

        .top-nav--navigation {
            &__left {
                a {
                    font-size: 16px;

                    &:not(:last-child) {
                        margin-right: 10px;
                    }

                    &.button--solid--cta--block {
                        font-size: 14px;
                        padding: 10px 12px;
                    }
                }
            }
        }
    }

    @media screen and (max-width:1200px) {
        .button--solid--cta--block {
            padding: 7px 8px !important;
        }

        .top-nav--link-wrp {
            margin-left: 20px;

            a {
                &:not(:last-child) {
                    margin-right: 10px;
                }
            }
        }

        .top-nav--navigation,
        .top-nav--head {
            padding-left: 15px;
            padding-right: 15px;
        }

        .top-nav--navigation {
            padding: 13px 20px;

            &__left {
                a {
                    img {
                        margin-right: 5px;
                    }

                }
            }
        }
    }

    @media screen and (max-width:991px) {
        &.sticky {
            transform: translateY(-37px);
            -webkit-transform: translateY(-37px);
            -moz-transform: translateY(-37px);
            -ms-transform: translateY(-37px);
            -o-transform: translateY(-37px);
        }

        .button--solid--cta--block {
            width: 200px;
            margin: auto !important;
        }
.d-flex{
        display:block;
        width:100%;
      }
        .top-nav--link-wrp {
            margin: 0;

            a {
                &:not(:last-child) {
                    margin-right: 0;
                }
            }
        }

        .top-nav--hamburger {
            top: 16px;
        }

        .hide-on-md {
            display: none !important;
        }

        .show-on-md {
            display: block !important;
        }

        .top-nav--head {
            background: #057FB3;

            p {
                display: none;
            }
        }

        .top-nav--navigation {
            background: rgba(255, 148, 25, 1);

            &__left {
                a.button--solid--cta--block {
                    font-size: 12px;
                    padding: 8px 6px;
                }
            }
        }
    }
}


/*
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(5,127,179,0.8);
    box-shadow: 0 0 0 0 rgba(5,127,179,0.8);
  }

  70% {
      -moz-box-shadow: 0 0 0 8px rgba(214,32,39,0);
      box-shadow: 0 0 0 8px rgba(214,32,39,0);
  }

  100% {
      -moz-box-shadow: 0 0 0 0 rgba(214,32,39,0);
      box-shadow: 0 0 0 0 rgba(214,32,39,0);
  }
}
*/

// New Css for the dropdown menu

.nav-dropdown{
.top-nav--wrapper {
    transition: height 0.35s ease;
    -webkit-animation: height 0.35s ease;
    .top-nav--navigation{
        transition: height 0.35s ease;
    -webkit-animation: height 0.35s ease;
    }
    .has-submenu {
        position: relative;

        a {
            margin: 0 !important;

        }

        &__wrapper {
            display: inline-flex;
            align-items: center;
            gap: 10px;
            position: relative;

            >a {
                color: #fff;
                position: relative;

                @media screen and (min-width:992px) {
                    &:after {
                        content: "";
                        position: absolute;
                        display: block;
                        width: 0%;
                        background: #fff;
                        height: 2px;
                        bottom: -4px;
                        left: 0;
                        transition: .4s ease all;
                        -webkit-transition: .4s ease all;
                        -moz-transition: .4s ease all;
                        -ms-transition: .4s ease all;
                        -o-transition: .4s ease all;
                        transform: none;
                    }

                    &:hover {
                        &:after {
                            width: 100%;
                        }

                    }
                }
            }

            span {
                @media screen and (min-width:992px) {
                    position: absolute;
                }

                cursor: pointer;
                position:relative;
                display:inline-block;
                background-image: url("data:image/svg+xml,%0A%3Csvg width='17' height='11' viewBox='0 0 17 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.5 10.1406L0.25 1.8906L1.72812 0.412476L8.5 7.21873L15.2719 0.44685L16.75 1.92497L8.5 10.1406Z' fill='%23ffffff'/%3E%3C/svg%3E%0A");
                width:11px;
                height:8px;
                background-repeat: no-repeat;
                background-size:contain;
                right:-17px;
                top:50%;
                transform: translateY(-50%);
                -webkit-transform: translateY(-50%);
                -moz-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                -o-transform: translateY(-50%);
            }
        }

        .submenu {
            list-style: none;
            margin: 0;
            padding: 0;
            overflow: hidden;

            li {
                text-align: start;

                a {
                    display: block;
                    padding: 12px 16px;
                    color: #495464;
                    text-align: start;
                    margin: 0;

                    @media screen and (max-width:991px) {
                        color: #fff;
                    }

                    @media screen and (min-width:992px) {
                        &:hover {
                            background: rgba(255, 148, 25, 0.85);
                            color: #fff;
                        }
                    }
                }
            }
        }
    }

    .top-nav--link-wrp {
        // @media screen and (min-width: 992px){
        //     height: auto !important;
        // }
        @media screen and (max-width: 992px){
            display: block;
            max-width: none;
           
            width: 100%;
            ul{
                margin-top: 15px;
            }
        }
        >ul {
            display: flex;
            align-items: center;
            list-style: none;
            padding: 0;
            margin: 0;
            >li {
                &:not(:last-child) {
                    @media screen and (min-width:992px) {
                        margin-right: 35px;

                        @media screen and (max-width:1200px) {
                            margin-right: 20px;
                        }
                    }
                }

                >a {
                    font-size: 18px;
                    color: #fff;
                    font-weight: 400;
                    position: relative;

                    @media screen and (min-width:992px) {
                        &:not(.button--solid--cta--block) {
                            &:after {
                                content: "";
                                position: absolute;
                                display: block;
                                width: 0%;
                                background: #fff;
                                height: 2px;
                                bottom: -4px;
                                left: 0;
                                transition: .4s ease all;
                                -webkit-transition: .4s ease all;
                                -moz-transition: .4s ease all;
                                -ms-transition: .4s ease all;
                                -o-transition: .4s ease all;
                                transform: none;
                            }
                        }

                        &:hover {
                            &:not(.button--solid--cta--block) {
                                &:after {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (min-width:992px) {
        .submenu {
            visibility: hidden;
            opacity: 0;
            position: absolute;
            top: 40px;
            left: 0;
            width: 100%;
            z-index: -1;
            transition: .5s ease all;
            display: block;
            border: 0;
            box-shadow: none;
            margin: 0;
            min-width: 150px;
            position: absolute;
            background: #fff;
            border-radius: 15px;
            margin-top: 10px;
            box-shadow: 0 0 12px rgba(0, 0, 0, .3);
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            -ms-border-radius: 10px;
            -o-border-radius: 10px;
        }

        .has-submenu {
            &:hover {
                .submenu {
                    visibility: visible;
                    opacity: 1;
                    z-index: 999;
                    //transform: translate(-50%, 0);

                }
            }
        }
    }

    @media screen and (max-width:991px) {
        .top-nav--link-wrp {
            >ul {
                flex-direction: column;
                justify-content: center;

                >li {
                    width: 100%;
                    text-align: start;

                    a {
                        text-align: left;
                        padding: 10px 0;
                        display: block;
                    }



                    >div {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 18px;

                        >span {
                            position: static;
                            transform: none;
                        }
                    }
                }

                &.md-center {
                    text-align: center;

                    li {
                        text-align: center;

                        a {
                            text-align: center !important;
                        }
                    }
                }
            }
        }

        .submenu {
            max-height: 0;
                overflow: hidden;
                transition: max-height 0.5s ease;
                

            li {

                a {
                    padding: 10px !important;
                }
            }
        }

        .has-submenu {
            &__wrapper {
                width: 100%;
                justify-content: space-between !important;

                span {
                    right: 0;
                    width: 17px;
                    height: 10px;
                }
            }

            &.active {
                .submenu{
                    max-height:max-content;
                }
                .has-submenu {
                    &__wrapper {
                        span {
                            transform: rotate(180deg);
                        }
                    }
                }
            }
        }
    }
}




}
// .menu-active {
//     .nav-dropdown .top-nav {
//         &--wrapper {
//             height: 100%;

//         }
//         &--navigation {
//             height: 100%;

//         }
//     }
// }