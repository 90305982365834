.slick-initialized .slick-slide.row {
  display: flex;
}

.slick-slide:focus {
  outline: none;
}

.slide-testimonial {
  h4 {
    margin-bottom: 0;

    +p {
      margin-top: 0;
    }
  }

  p.quote {
    font-style: italic;
  }

  .glide__slide {
    margin: 0;
  }

  .glide__bullets {
    bottom: -2em;
  }

  .glide__bullet {
    background-color: rgba(0,0,0,0.25);
    width: 12px;
    height: 12px;
    margin: 0 0.5em;
    box-shadow: initial;

    &:hover, &:focus {
      background-color: rgba(0,0,0,1);
      border: initial;
    }
  }

  .glide__bullet--active {
    background-color: rgba(0,0,0,0.75);
    border: initial;
  }
}

.glide-tint {
  color: #ffffff;
  background-color: rgba(0,0,0,0.3);
  text-shadow: 0 0 30px black;

  h3 {
    font-size: 1.4em;
  }
}

.aspect-ratio-container {
  width: 100%;
  padding-bottom: 100%;
  position: relative;

  > * {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.vertical-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.glide__slides.orange {
  background: #fff9f2;
  padding: 30px 0;
}