.Branche {
  position: relative;
  padding: 80px 0; }
  .Branche .brn-primary:hover{
      color: #ff9419D9;
  }
  .Branche .container-fluid{
        margin-right: auto;
      margin-left: auto;
      padding-right: 2rem;
      padding-left: 2rem;
          margin: auto;
    max-width: 900px;
  }
  .Branche .section-head {
    margin-bottom: 65px;
  text-align:center; }
  .Branche ul {
    list-style: none;
    padding: 0;
    margin: 0; }
    .Branche ul li {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      margin: 0 15px; }
      
      .Branche ul li:not(:last-child) {
        margin-bottom: 18px; }
      .Branche ul li a {
            color: #333;
        font-size: 18px; }
        .Branche ul li a:hover{color: #ff9419D9;}
    .Branche ul svg, .Branche ul img {
      width: 70px;
      min-width: 70px;
      margin-right: 20px; }
  .Branche .button-row {
    margin: 50px 0; }
     .Branche .images-wrapper{
      position:relative;
      width:100%;
      height:615px;
     }
     .Branche .images-wrapper .image{
      position:absolute;
      max-width:60%;
      height:230px;
     }
     .Branche .images-wrapper .image img{
      width:100%;
      height:100%;
      object-fit:cover;
      border-radius:25px;
      overflow:hidden;
     }
     .Branche .images-wrapper .image:first-child{
      right:0;
      top:0;
     }
     .Branche .images-wrapper .image:nth-child(2){
      left:0;
      top:150px;
     }
      .Branche .images-wrapper .image:nth-child(3){
      right:0;
      margin:auto;
      bottom:50px;
     }
  @media screen and (max-width: 767px) {
    .Branche {
      padding: 50px 0; }
      .Branche .images-wrapper .image{
        height:200px;
      }
      .Branche .images-wrapper{
        height:100%;
        width: 90%;
        margin:auto;
        padding-top: 25px;
        height: 550px;
      }
      .Branche .images-wrapper .image:nth-child(2) {
          // top: 30px;
      }
      .Branche .images-wrapper .image:nth-child(3) {
          bottom: 40px;
      }
      .Branche .row > div{
          flex-basis: 100%;
          max-width: 100%;
      }
}
@media screen and (max-width: 500px) {
  
  .Branche .images-wrapper{
      width: 100%;
    }
}
