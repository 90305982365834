.customers--logo-wrp {
  height: 100px;
  position: relative;
  margin-bottom: 20px;

  a {
    width: 100%;
    height: 100%;
  }
}

.customers--logo {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: block;
  max-width: 95%;
  max-height: 95%;
  max-width: calc(100% - 30px);
  max-height: calc(100% - 30px);
}

.trust--logo-wrp {
  height: 120px;
  position: relative;
  margin-bottom: 20px;

  a {
    width: 100%;
    height: 100%;
  }
}

.trust--logo {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: block;
  max-width: 100%;
  max-height: 100%;
}