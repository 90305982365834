ul.orange-bulletpoints {
  list-style: none;
  padding-left: 1.4em;

  li {
    &:before {
      color: #ff9419;
      content: "\25CF";
      font-size: 1em;
      position: relative;
      top: 0em;
      left: -1em;
      margin-right: -0.55em;
    }
  }
}

