h1, h2, h3, h4, h5, h6 {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 700;
  margin-top: 10px;
  

  &.dark {
    font-color: $grey_5;
  }

  &.light {
    font-color: $clean-white;
  }

  &.regular {
    font-weight: 300;
  }
}

h2, h3, h4, h5, h6{
  font-weight: 500;
}


p {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: 0.3px;
}

a {
  text-decoration: none;
  display: inline-block;
  position: relative;
  color: $crew-range-085;
  -webkit-transition: all 200ms;
  transition: all 200ms;

  &:hover {
    text-decoration: none;
    color: $crew-range;
  }

  &.animated:after {
    content: "";
    background-color: $crew-range-085;
    width: 0;
    height: 2px;
    position: absolute;
    bottom: 0;
    left: 0;
    -webkit-transition: all 200ms;
    transition: all 200ms;
  }

  &.animated:hover:after {
    width: 100%;
  }
}