.our-departments{
  $self: &;
  background:#FF9419D9;
  padding:50px 0;
  padding-top: 30px;
  .banner--heading{
      color:#FFFFFF;
       font-size: 1.5em;
        text-align: center;
  }
  &__items{
      
      display:flex;
      flex-wrap:wrap;
      justify-content:center;
      gap:30px;
      &--image{
          height:348px;
          img{
              max-width:100%;
              height:100%;
              width:100%;
              object-fit:cover;
               transition: all 0.5s ease;
          }
      }
      &--item{
          position:relative;
          border-radius: 15px;
          -webkit-border-radius: 15px;
          -moz-border-radius: 15px;
          -ms-border-radius: 15px;
          -o-border-radius: 15px;
          overflow:hidden;
          
          transition: all 0.6s ease;
          @media screen and (min-width: 1200px){
              width:348px;
          }
          &:hover {
              @media only screen and (min-width: $screen-sm-min) {
                box-shadow: 0 0 20px -2px rgba(0,0,0,0.3);
                transform: scale(1.02);
              }
          }
          .btn-link{
              width: 100%;
          }
         
      }
      &--content{
          position:absolute;
          background: rgba(0, 0, 0, 0.7);
          color:#cfcfcf;
          bottom:0;
          left:0;
          width:100%;
          padding:1.26rem 1rem 1.125rem;
          text-align:center;
          p{
              font-size: 2.5rem;
              letter-spacing: 1px;
              margin-top:0;
              color:#fff;
          }
          span{
             background: rgba(255, 148, 25, 0.85);
              color:#FFFFFF;
              border-radius: 4px;
              line-height: 1.42857;
              padding: 6px 20px;
              display: inline-block;
              text-align: center;
              vertical-align: middle;
              @media screen and (min-width:1025px){
                  &:hover{
                      opacity:.8;
                  }
              }
          }
      }
      @media screen and (max-width: 1200px){
          gap: 2%;
          &--item{
              width: 32%;
              margin: 10px 0px;
          }
      }
      @media screen and (max-width:991px){
          &--item{
              width:45%;
          }
          &--image{
              height:300px;
          }
          &--content{
              p{
                  font-size: 1.8rem;
              }
          }
      }
      @media screen and (max-width:667px){
          &--item{
              width:100%;
          }
          &--image{
              height:300px;
          }
      }
  }
}

.teamMembers{
  background:#FF9419D9;
  padding:3.125rem 0;
  .banner--heading{
      color:#FFFFFF;
       font-size: 1.5em;
       text-align:left;
       text-align: center;
  }
  &__items{
      display:flex;
      flex-wrap:wrap;
      justify-content:center;
      gap:30px;
      &--item{
          width:348px;
          @media screen and (max-width: 1200px){
              width:32%;
          }
      }
      &--image{
          height:348px;
          img{
              max-width:100%;
              height:100%;
              width:100%;
              object-fit:cover;
          }
      }
      &--content{
          position:absolute;
          background: rgba(0, 0, 0, 0.7);
          color:#cfcfcf;
          left:0;
          padding:1.28rem;
          bottom:-100%;
          transition:.5s ease all;
          -webkit-transition:.5s ease all;
          -moz-transition:.5s ease all;
          -ms-transition:.5s ease all;
          -o-transition:.5s ease all;
          width:100%;
          height:100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          h2, h3{
              font-size:2rem;
              margin-top:0;
              margin-bottom:15px;
              font-weight:500;
               color:#cfcfcf;
          }
          h3{
              font-size: 1.5rem;
          }
          p{
              font-size: 14px;
              line-height: 1.4;
              letter-spacing: 0.3px;
              margin-top:0;
               color:#cfcfcf;
          }
      }
      &--item{
          position:relative;
          border-radius: 15px;
          -webkit-border-radius: 15px;
          -moz-border-radius: 15px;
          -ms-border-radius: 15px;
          -o-border-radius: 15px;
          overflow:hidden;
          cursor:pointer;
          &:hover{
              @media screen and (min-width: 1025px){
                  .teamMembers__items--content{
                      bottom:0;
                  }
              }
          }
          
          &.show{
              @media screen and (max-width: 1024px){
                  .teamMembers__items--content{
                      bottom:0;
                  }
              }
          }
      }
      @media screen and (max-width: 1200px){
          gap: 2%;
          &--item{
              width: 32%;
              margin: 10px 0px;
          }
      }
       @media screen and (max-width:991px){
          &--item{
              width:45%;
          }
          &--image{
              height:300px;
          }
      }
      @media screen and (max-width:667px){
          &--item{
              width:100%;
          }
      }
  }
}
