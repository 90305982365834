@each $breakpoint_name in $breakpoint_names {
  $i: index($breakpoint_names, $breakpoint_name);
  $breakpoint_edge: nth($breakpoint_edges, $i);

  @media (min-width: $breakpoint_edge) {
    .text-center-#{$breakpoint_name} {
      text-align: center;
    }

    .text-left-#{$breakpoint_name} {
      text-align: left;
    }

    .text-right-#{$breakpoint_name} {
      text-align: right;
    }

    .overflow-hidden-#{$breakpoint_name} {
      overflow: hidden;
    }
  }
}

@mixin generate-spacing($position, $breakpoint_name, $size_name, $size) {
  .#{$position}-#{$size_name}-#{$breakpoint_name} {
    #{$position}: $size;
  }

  .#{$position}-vertical-#{$size_name}-#{$breakpoint_name}{
    #{$position}-top: $size;
    #{$position}-bottom: $size;
  }

  .#{$position}-horizontal-#{$size_name}-#{$breakpoint_name} {
    #{$position}-left: $size;
    #{$position}-right: $size;
  }

  .#{$position}-top-#{$size_name}-#{$breakpoint_name} {
    #{$position}-top: $size;
  }

  .#{$position}-bottom-#{$size_name}-#{$breakpoint_name} {
    #{$position}-bottom: $size;
  }

  .#{$position}-left-#{$size_name}-#{$breakpoint_name}{
    #{$position}-left: $size;
  }

  .#{$position}-right-#{$size_name}-#{$breakpoint_name} {
    #{$position}-right: $size;
  }
}

@each $spacing_name in $spacing_names {
  $i: index($spacing_names, $spacing_name);
  $spacing_size: nth($spacing_sizes, $i);

  @each $breakpoint_name in $breakpoint_names {
    $i: index($breakpoint_names, $breakpoint_name);
    $breakpoint_edge: nth($breakpoint_edges, $i);

    @media (min-width: $breakpoint_edge) {
      @include generate-spacing("padding", $breakpoint_name, $spacing_name, $spacing_size);
      @include generate-spacing("margin", $breakpoint_name, $spacing_name, $spacing_size);
    }
  }
}

@each $color-name in $color-list-names {
  $i: index($color-list-names, $color-name);
  $color: nth($color-list, $i);

  .background-color-#{$color-name} {
    background-color: $color;
  }
}

.image--responsive {
  position: relative;
  max-width: 100%;
}

.image--overflowing {
  width: 105%;
  height: auto;
  position: relative;
  margin-left: $spacing-large/-2;
  max-width: calc(100% + #{$spacing-large});
}