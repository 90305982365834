.banner--items {
  @media only screen and (min-width: 48em) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;

    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;

    flex-wrap: wrap;
  }
}

.banner--items--prices {
  @media only screen and (min-width: 48em) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;

    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;

    flex-wrap: nowrap;
  }
}

.banner--item {
  padding: 20px;
  background: #fff9f2;

  @media only screen and (min-width: 48em) {
    width: 32%;

    &:nth-child(n+4) {
      margin-top: 20px;
    }

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.banner--item--price {
  padding: 20px;

  @media only screen and (min-width: 48em) {
    width: 32%;

    &:nth-child(n+5) {
      margin-top: 20px;
    }

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.banner--heading-wrp {
  text-align: center;
}

.banner--heading,
.banner--item-heading {
  width: 100%;
  font-family: 'Source Sans Pro', sans-serif;
  color: $grey_5;
  text-align: center;
}

.banner--item-heading {
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 0;
  color: $grey_4;
}

.banner--text {
  font-family: 'Source Sans Pro', sans-serif;
  font-style: italic;
  color: $grey_4;
  text-align: center;
}

.banner--footer {
  font-family: 'Source Sans Pro', sans-serif;
  color: $grey_4;
  text-align: center;
  margin-top: auto;
}

.banner--icon {
  color: $crew-range;
  font-size: 50px;
}

.banner--image {
  border-radius: 50%;
  max-height: 100px;
}

.usp {
  .banner--item {
    background: inherit;
  }

  a.banner--item:hover {
    background: #fff9f2;
  }
}
