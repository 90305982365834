$some-white: #fbfbfb;

.counter-container {
  background-color: $crew-range-085;
  max-width: 100%;
  color: $some-white;

  .counter-animation {
    font-size: 2em;
    border: 0.167em solid $some-white;
    border-radius: 0.44em;
    padding: 0.5em 0.5em;
    letter-spacing: 0.2em;
    min-width: 10em;
    display: inline-block;
    font-weight: 600;
    margin-top: 0.1em;
    margin-bottom: 0.4em;

    @media (max-width: $screen-sm-min) {
      font-size: 1.3em;
    }
  }
}
