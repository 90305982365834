.plans.column {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem 0;
  background: #fef9f2;
  transition: 100ms;
}

.plans h3 {
  width: 100%;
  text-align: center;
}

.plans.heading {
  text-align: center;
}

.plans ul {
  align-self: stretch;
  list-style: none;
  padding: 0;
  margin: 10px 0;
}

.plans ul li {
  border-top: 3px solid #ffffff;
  width: 100%;
  padding: 5px 20px;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.plans a {
  line-height: 1em;
  height: 1em;
  opacity: 0.7;
  margin-left: auto;
}

.plans span {
  font-style: crewmeister;
  margin: 0 1rem;
  color: #ed9c42;
}

.plans span.check {
  font-size: 4rem;
}

.plans span.icon {
  margin-bottom: 20px;
  margin-top: 20px;
  width: 100%;
  text-align: center;
  font-size: 7rem;
}

.plans p {
  text-align: center;
}

@media only screen and (min-width: 992px) {
  .plans.column:hover {
    filter: drop-shadow(0 0 1rem lightgray);
    transform: scale(1.02);
  }
}
