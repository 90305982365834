$iphone5_height: 500px;
$iphone6plus_height: 736px;
$iosURLBarHeight: 50px;

.hero--wrp-referral {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  background-size: cover;
  height: 100vh;
  height: calc(100vh - #{$iosURLBarHeight});

  min-height: $iphone5_height;
  max-height: $iphone6plus_height;
}

.hero--wrp-career {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  background-size: cover;
  height: 85vh;
  height: calc(85vh - #{$iosURLBarHeight});

  min-height: $iphone5_height;
  max-height: $iphone6plus_height;

    
  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    background: rgba(255, 255, 255, 0.85);
  }
}

.hero--wrp {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  background-size: cover;
  height: 80vh;
  height: calc(80vh - #{$iosURLBarHeight});

  min-height: $iphone5_height;
  max-height: $iphone6plus_height;

  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    background: rgba(255, 255, 255, 0.85);

  }
}

.hero--wrp-meta {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  background-size: cover;
  height: 100vh;
  height: calc(100vh - #{$iosURLBarHeight});

  min-height: $iphone5_height;
  max-height: $iphone6plus_height;

  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    background: rgba(255, 255, 255, 0.945);

  }
}

.hero--wrp-new {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  background-size: cover;
}

.hero--form-wrp {
  width: 100%;
  z-index: 1;
  position: relative;
  -webkit-transform: none;
  transform: none;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  padding: $spacing-large;
}

.hero--title-wrp {
  padding: 35px $spacing-large 0;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.hero--title-career {
  padding: 35px $spacing-large 0;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center + 100px;
}

.hero--try-crewmeister,
.hero--title,
.hero--form {
  position: relative;
  z-index: 1;
  max-width: $container-max-width;
  margin: auto;
  width: 100%;
}

.hero--title,
.hero--subtitle,
.hero--try-crewmeister {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 700;
  color: $grey_5;
  font-size: 20px;
}

.hero--title--referral,
.hero--subtitle--referral,
.hero--try-crewmeister--referral {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 700;
  color: $clean-white;
  font-size: 20px;
}

.hero--title-group {
  position: relative;
  z-index: 1;
  margin: auto;
}

.hero--subtitle {
  font-size: 16px;
}

.hero--title {
  font-size: 22px;
}

.product-video {
  padding-top: 20px;
  margin-top: 45px;
  height: 450px;
  width: 800px;
  z-index: 5;
  position: relative;
  display: none;
  @media (max-width: 900px) {
   padding-top: 100px; 
   width: 100%;
   height: 340px;
  }
}



@media only screen and (min-width: $screen-sm-min) {
  .hero--wrp {
    height: 80vh;
    min-height: 400px;
    max-height: 99999vh;
  }

  .hero--title {
    font-size: 36px;
  }

  .hero--subtitle,
  .hero--try-crewmeister {
    font-size: 25px;
  }

  .hero--title-group {
    padding: 20px;
    max-width: $container-max-width;
  }

  .hero--wrp, .product-video  {
    margin-top: 57px;
  }
}

/* source: https://daneden.github.io/animate.css/ (MIT) */
@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.hero--arrow-animation {
  animation-name: pulse;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  cursor: pointer;
}
