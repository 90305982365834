.jobs--list {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 300;
  font-size: 12px;
  margin: 0px;
  padding-left: 0px;
  list-style-type: none;

  li {
    margin: 0 -0.5em;
  }

  a {
    display: block;
    border: 1px solid $grey_1;
    border-radius: 4px;
    margin: 1em 0;
    padding: 0.5em;
    color: $grey_5;
  }

  a:hover {
    border: 1px solid $crew-range;
  }
}

.jobs--list--title {
  font-weight: bold;
}

.jobs--list--title, .jobs--list--type, .jobs--list--location {
  margin: 0;
}
