// Better font rendering
html, body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  height: 100%;
  font-family: 'Source Sans Pro', sans-serif;
}

// Smothing scrolling
html {
  scroll-behavior: smooth;
}
.scroll-padding {
  margin-top: -60px;
  padding-top: 60px;
}

// Remove FF outlines borders
a:active, a:focus {
  outline: none;
}

.row {
  margin-left: 0;
  margin-right: 0;
}

.container-fluid {
  margin: auto;
  max-width: $container-max-width;

  &.slim {
    max-width: 500px;
  }

  &.normal {
    max-width: 980px;
  }
}

// target ie10 and ie11
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  // fix cut of text in ie
  // see http://stackoverflow.com/a/26641422/3002239
  body {
    box-shadow: 0 0 0;
  }
}

.spacer {
  display: block;
}

// for aspect-ratio useragent style
img {
  height: auto;
}
