// Colors
$clean-white: #ffffff;
$clean-white-075: rgba(255,255,255,.75);

$beige: #fefdfb;

$crew-range: rgba(255,148,25, 1);
$crew-range-085: rgba(255,148,25,.85);
$crew-range-050: rgba(255,148,25,.50);


$false-cherry: #f50722;
$sustainable-green: #62c696;
$antihero-green: #62c6bc;
$official-note-blue: #46ade9;

$brown_1: #ebe4de;
$brown_2: #d8c5b5;
$brown_3: #b39b88;
$brown_4: #887464;

$grey_1: #CECECE;
$grey_2: #A5A4A4;
$grey_3: #91908F;
$grey_4: #686664;
$grey_5: #42403E;

$color-list-names: "crew-range" "beige";
$color-list: $crew-range $beige;

// Spacing
$spacing-small: 5px;
$spacing-medium: 10px;
$spacing-large: 2rem;
$spacing-xlarge: 30px;
$spacing-xxlarge: 60px;

$spacing_names: xxlarge xlarge large medium small none;
$spacing_sizes: $spacing-xxlarge $spacing-xlarge $spacing-large $spacing-medium $spacing-small 0;

// Breakpoints
$screen-xs-min: 480px;
$screen-sm-min: 768px;
$screen-md-min: 992px;
$screen-lg-min: 1200px;

$breakpoint_names: xs sm md lg;
$breakpoint_edges: 0px $screen-sm-min $screen-md-min $screen-lg-min;

// Z-Indexes
$z-index-navigation: 10;

// Container
$container-max-width: 1200px;