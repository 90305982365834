.top-nav--wrp {
  background: $crew-range-085;
  padding: 7px 5px;
  line-height: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: $z-index-navigation;
  border-bottom: 2px solid $crew-range;
}

.top-nav--link-wrp {
  text-align: center;
  overflow: hidden;
  width: 100%;
}

.top-nav--logo {
  display: block;
  height: 15px;
  // IOS offset FIX
  transform: translate(0, 0px);
}

.top-nav--logo-wrp {
  width: calc(100% - 28px);
  padding: 7px 0;
}

.top-nav--contact {
  padding-bottom: 10px;
  width: calc(100% - 28px);

  &.top-nav--link {
    text-align: left;
  }
}

.top-nav--hamburger {
  display: block;
  width: 25px;
  position: absolute;
  right: 10px;
  top: 10px;
}

.top-nav--hamburger-row {
  display: block;
  width: 100%;
  height: 2px;
  background: $clean-white;
  margin: 5px 0;
}

.top-nav--link,
.top-nav--link--bold {
  text-align: center;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: $clean-white;
  padding: 10px 0;

  display: block;
  position: relative;

  &.active,
  &:focus,
  &:active,
  &:hover {
    color: $clean-white;
    text-decoration: none;
  }
}

.top-nav--link--bold {
  font-weight: 700;
}

@media only screen and (min-width: 992px) {
.top-nav--link-wrp {
    padding-top: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 68%;
    max-width: 520px;
    overflow: visible;

    &[style] {
      height: auto !important;
    }
  }
}

@media only screen and (min-width: $screen-sm-min) {
  .top-nav--wrp {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 20px 20px 17px;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .top-nav--contact {
    width: 40%;
    min-width: 320px;
    padding-bottom: 0;
  }

  .top-nav--logo-wrp {
    height: 18px;
    width: auto;
    // IOS offset FIX
    transform: translate(0, 1px); // vertical center of box with border-bottom
    padding: 0;
  }

  .top-nav--logo {
    width: auto;
  }


  .top-nav--hamburger {
    display: none;
  }

  .top-nav--link,
  .top-nav--link--bold {
    text-align: left;
    padding: 0 3px;
  }

  // .top-nav--link-wrp {
  //   padding-top: 0;
  //   display: -webkit-box;
  //   display: -webkit-flex;
  //   display: -ms-flexbox;
  //   display: flex;
  //   -webkit-box-pack: justify;
  //   -webkit-justify-content: space-between;
  //   -ms-flex-pack: justify;
  //   justify-content: space-between;
  //   width: 68%;
  //   max-width: 520px;
  //   overflow: visible;

  //   &[style] {
  //     height: auto !important;
  //   }
  // }

  .top-nav--link--animation {
    &:hover:after,
    &.active:after {
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      background: $clean-white;
      height: 2px;
      bottom: -4px;
      left: 50%;
      -webkit-transform: translate(-50%, 0);
      transform: translate(-50%, 0);
    }
  }
}