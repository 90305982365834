.productpage--items {
  @extend .banner--items;
  justify-content: center;
}

.productpage--item {
  padding: 20px;
  background: #fff9f2;

  @media only screen and (min-width: 48em) {
    width: 32%;
    margin: 0 1%;

    &:nth-child(3n+1) {
      margin-left: 0;
    }

    &:nth-child(3n+3) {
      margin-right: 0;
    }

    &:nth-child(n+4) {
      margin-top: 20px;
    }
  }

  @media only screen and (max-width: 767px) {
    width: 100%; // ugly hack
  }

  ul {
    display: inline-block;
  }
}

a.productpage--item {
  color: inherit;
  text-decoration: inherit;

  &.cardanimation {
    &:hover {
      @media only screen and (min-width: $screen-sm-min) {
        box-shadow: 0 0 20px -2px rgba(0,0,0,0.3);
        transform: scale(1.02);
      }
    }
  }
}

.videoicon {
  height: 100px;
  width: auto;
  padding: 10px;
  margin-bottom: -6px;

  ::-webkit-media-controls {
    display: none !important; // for iOS
  }
}
