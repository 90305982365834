//
// Component animations
// --------------------------------------------------

// Heads up!
//
// We don't use the `.opacity()` mixin here since it causes a bug with text
// fields in IE7-8. Source: https://github.com/twbs/bootstrap/pull/3552.

.fade {
  opacity: 0;
  @include transition(opacity .15s linear);
  &.in {
    opacity: 1;
  }
}

.collapse {
  display: block;
  overflow: hidden;
  @include transition(height .35s ease);

  &.in      { display: block; }
  // [converter] extracted tr&.in to tr.collapse.in
  // [converter] extracted tbody&.in to tbody.collapse.in
}

tr.collapse.in    { display: table-row; }

tbody.collapse.in { display: table-row-group; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  @include transition-property(height, visibility);
  @include transition-duration(.35s);
  @include transition-timing-function(ease);
}


/* source: https://daneden.github.io/animate.css/ (MIT) */
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -50%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.zufriedenheitsgarantie .scroll-animation div {
  opacity: 0;
}

.zufriedenheitsgarantie .scroll-animation.was-in-view div {
  animation-name: fadeInDown;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-delay: 0.5s;
}

.zufriedenheitsgarantie .scroll-animation.was-in-view div:nth-child(2) {
  animation-delay: 1.0s;
}

.zufriedenheitsgarantie .scroll-animation.was-in-view div:nth-child(3) {
  animation-delay: 1.5s;
}
