input {
  -webkit-appearance: none;
  border-radius: 0;
}

input[type="checkbox"] {
  -webkit-appearance: checkbox;
  margin-top: 6px;
}

.input-group {
  padding-top: 8px;
  min-height: 72px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.input--shapeless {
  text-align: center;
  display: block;
  background-color: transparent;
  background-image: none;
  width: 100%;
  padding: 1.5px 0 6.5px;
  font-size: 18px;
  line-height: 1.42857;
  border-width: 0 0 2px 0;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 300;
  border-bottom-color: $crew-range;
  border-radius: 0;


  &.align-left {
    text-align: left;
  }

  &.dark {
    border-width: 0 0 2px 0;
    border-bottom-color: $grey_1;
    border-style: solid;
    color: $grey_4;

    &:focus {
      border-bottom-color: $grey_3;
      color: $grey_4;
    }
  }

  &.light {
    border-bottom-color: $clean-white-075;
    color: $clean-white;

    &:focus {
      color: $clean-white;
      border-bottom-color: $clean-white;
    }
  }

  &:focus {
    outline: none;
  }
}


.label--shapeless {
  color: $crew-range;
  font-size: 14px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;

  &.light {
    color: $clean-white;
  }
}

$placeholder-color: $grey_3;

::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 300;
  color: $placeholder-color;
}

:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 300;
  color: $placeholder-color;
  opacity: 1;
}

::-moz-placeholder { /* Mozilla Firefox 19+ */
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 300;
  color: $placeholder-color;
  opacity: 1;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 300;
  color: $placeholder-color;
}

:placeholder-shown { /* Standard (https://drafts.csswg.org/selectors-4/#placeholder) */
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 300;
  color: $placeholder-color;
}

::-webkit-input-placeholder {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 300;
  color: $placeholder-color;
}

::-moz-placeholder {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 300;
  color: $placeholder-color;
}

:-ms-input-placeholder {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 300;
  color: $placeholder-color;
}

::placeholder {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 300;
  color: $placeholder-color;
}

.label--shapeless--big {
  color: $grey_4;
  font-size: 18px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
}


