.panel {
  margin-bottom: 18px;
}

.panel + .panel  {
  margin-top: -13px;
}

// Panel contents
.panel-body {
  padding: 0 15px;
  @include clearfix;
}

// Optional heading
.panel-heading {
  padding: 0;
  @include border-top-radius(($panel-border-radius - 1));
}

// Within heading, strip any `h*` tag of its default margins for spacing.
.panel-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: ceil(($font-size-base * 1.125));
  color: inherit;

  > a,
  > small,
  > .small,
  > small > a,
  > .small > a {
    color: inherit;
    display: block;
  }

  > a:focus {
    text-decoration: none;
  }
}

.panel-title a {
  margin-left: 2.6ex;
}

.panel-title a::before {
  font-family: 'icomoon' !important;
  speak: none;
  content: "\f139";
  position: absolute;
  left: -2ex;
  margin: 0;
  color: #777777;
}

.panel-title a.collapsed::before {
  content: "\f13a";
}