.footer--wrp {
  background: $beige;
  border-top: 2px solid $crew-range;
  padding-top: $spacing-xlarge;
  padding-bottom: $spacing-xlarge;
}

.footer--link-list {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 300;
  font-size: 12px;
  margin: 0px;
  padding-left: 0px;
  list-style-type: none;
  a {
    color: $grey_5;
  } 
}

.footer--heading {
	font-size: 16px;
}

