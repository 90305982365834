.button--base {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 700;
  color: $crew-range;
  line-height: 1.42857;
  padding: 6px 30px;
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 0 solid transparent;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 19px;
  height: 40px;
  -webkit-transition: all 200ms;
  transition: all 200ms;

  &:focus {
    outline: none;
  }

  &:hover, &:active, &:focus {
    background: $crew-range-085;
    color: $clean-white;
    text-decoration: none;
    border-radius: 4px;
    box-shadow: 2px 2px 5px 0 rgba(0,0,0,0.3);
  }

  &:active {
    padding: 9px 30px 7px;
    background: $crew-range;
    font-size: 18px;
  }

  &.dropshadow {
    box-shadow: 0 12px 30px -8px rgba(0,0,0,0.3);
  }
}


.button--solid,
.button--solid--block,
.button--solid--cta,
.button--solid--cta--test,
.button--solid--cta--block {
  @extend .button--base;
  background: $clean-white;
}

.button--solid--block,.button--solid--cta--test, .button--solid--cta--block {
  display: block;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.button--solid--cta,
.button--solid--cta--block {
  border-radius: 4px;
  box-shadow: 2px 2px 5px 0 rgba(0,0,0,0.3);
  color: $clean-white;
  background: $crew-range-085;
  &:hover, &:active, &:focus {
    background: $crew-range;
  }
}

.button--solid--cta--test {
  border-radius: 4px;
  box-shadow: 2px 2px 5px 0 rgba(0,0,0,0.3);
  color: $clean-white;
  background: #057FB3;
  &:hover, &:active, &:focus {
    background: #057FB3;
  }
}

.button--solid {
  background: transparent;
  padding: 6px 15px;
}

.button--hollow {
  @extend .button--base;
  background: transparent;
}

.button--solid--cta--block--inverse {
  @extend .button--solid--cta;
  background: $clean-white;
  color: $crew-range-085;

  &:hover, &:active, &:focus {
    background: #fbfbfb;
    color: $crew-range;
  }
}


