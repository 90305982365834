.feature-page-header--wrp {
  padding-top: 80px;
  padding-bottom: $spacing-xlarge;
  position: relative;

  &:before {
    z-index: 0;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    background: $clean-white-075;
    width: 100%;
    height: 100%;
  }
}

.feature-page-header--navigation-link,
.feature-page-header--subheading,
.feature-page-header--heading {
  position: relative;
  z-index: 5;
}

.feature-page-header--heading {
  margin-bottom: $spacing-medium;
  position: relative;
  z-index: 5;
}

.feature-page-header--subheading {
  margin-bottom: 0;
}

.feature-page-header--navigation-link {
  display: inline-block;
  position: relative;
  color: $grey_5;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 300;
  font-size: 20px;

  &:hover, &:active {
    color: $grey_5;
    text-decoration: none;
  }

  &:before {
    content: "";
    background: $grey_5;
    width: 0;
    height: 2px;
    position: absolute;
    bottom: -3px;
    left: 0;
    -webkit-transition: width 200ms;
    transition: width 200ms;
  }

  &.active:before,
  &:hover:before {
    width: 100%;
    width: calc(100% - 13px);
  }


  &:last-child.active:before,
  &:last-child:hover:before {
    width: 100%;
  }

  &:after {
    content: "/"
  }

  &:last-child:after {
    content: ""
  }
}
