.sign-up--full-screen-wrp {
  height: 100%;
  background-position: top center;
  background-size: cover;
  background: #f0efed;

  &:before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 62px;
    background: #fc9430; // not $crew-range because of jpeg export
    z-index: 0;
  }
}

.sign-up--full-screen-inner-wrp {
  width: 960px;
  height: 100%;
  margin: auto;
  position: relative;
  z-index: 1;
}

.sign-up-grayoverlay {
  position: absolute; 
  left: 0;
  right: 0;
  width: 100%;
  height:100%;
  background-color: #000;
  opacity: 0.30;
}

.sign-up-1--form {
  background: $beige;
  display: inline-block;
  padding: 40px 30px;
  margin: 20px;
  margin-top: 100px;
  box-shadow: 20px 20px 20px rgba(0,0,0,.3);
  overflow: hidden;
}

/* make keyframes that tell the start state and the end state of our object */
@-webkit-keyframes teaserIn { to { opacity:1; } }
@-moz-keyframes teaserIn { to { opacity:1; } }
@keyframes teaserIn { to { opacity:1; margin-top: 100px; } }

.teaser-in {
  margin-top: 500px;

  -webkit-animation:teaserIn linear 1;  /* call our keyframe named fadeIn, use animattion ease-in and repeat it only 1 time */
  -moz-animation:teaserIn linear 1;
  animation:teaserIn linear 1;

  -webkit-animation-fill-mode: forwards;  /* this makes sure that after animation is done we remain at the last keyframe value (opacity: 1)*/
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
}

.sign-up-1--submit-button[disabled], button[disabled] {
  opacity: .3;
  cursor: no-drop;
}

.sign-up-1--heading {
  margin-top: 0;
  font-size: 25px;
  color: $grey_4;
}

.sign-up-1--input-size {
  width: 60%;
}

.sign-up-1--agbs {
  text-align: center;
  font-size: 14px;
  color: $brown_4;
}

.sign-up-2--wrp {
  background: $beige;
}

.sign-up-2--modal {
  background: $clean-white;
  box-shadow: 0 3px 3px 0 rgba(0,0,0,0.05);
  padding: 15px;

  @media (min-width: $screen-sm-min) {
    margin: 0 20px;
    padding: 30px;
  }
}

.sign-up-2--heading {
  margin-top: 0;
  font-size: 25px;
  color: $grey_4;
  text-align: left;
}

.sign-up-2--modal-feature {
  list-style-position: inside;
  padding-left: 0;
  text-align: left;
  font-weight: 600;
  font-family: 'Source Sans Pro', sans-serif;
  color: $grey_4;
  margin: 26px 0;

  &:last-child {
    margin-bottom: 0;
  }
}

.sign-up-2--logo {
  width: 100px;
}

.sign-up-2--hero {
  margin-top: 10vh;
  margin-bottom: 7vh;
}

.sign-up-2--teaser {
  color: $grey_4;
  font-size: 25px;
}

.sign-up-2--sub-teaser {
  color: $grey_4;
  font-size: 18px;
  font-weight: 600;
}

.sign-up-2--product-icon {
  color: $crew-range;
  display: block;
  font-size: 30px;
  margin: 10px 0 7px;
}

.sign-up-2--product {
  list-style: none;
  text-align: center;
  font-weight: 400;

  @media (min-width: $screen-sm-min) {
    float: left;
    width: 33.333%;
  }
}

.sign-up-3--heading {
  margin-top: 0;
  font-size: 25px;
  color: $grey_4;
}

.sign-up-3--form {
  @extend .sign-up-1--form;
  float: right;
  position: relative;
  margin-top: 62px;
  margin-right: 67px;

  &:before {
    position: absolute;
    content: "";
    display: block;

    top: -8px;
    right: 30px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid $beige;
  }
}

.sign-up-3--agbs {
  text-align: left;
  font-size: 14px;
  color: $brown_4;
}

.sign-up-3--input-size {
  width: 80%;
}

.sign-up-4--form {
  background: $clean-white;
  box-shadow: 0 3px 3px 0 rgba(0,0,0,0.05);
  padding: 15px;

  @media (min-width: 630px) {
    margin: 0 20px;
    padding: 30px;
  }
}

@media (min-width: $screen-md-min) {
  .sign-up-4--wrp {
    height: 100%;
    background-position: top center;
    background-size: cover;
    background: #fefdfa;

    &:before {
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      height: 69px;
      background: #ff9419;
      z-index: 0;
    }
  }
  .sign-up-4--inner-wrp {
    width: $screen-md-min;
    height: 100%;
    margin: auto;
    padding: 0.1px;
    position: relative;
    z-index: 1;
    background-image: url("/images/application_blur-loss-v2.png");
    background-repeat: no-repeat;
  }
  .sign-up-4--inner-wrp-en {
    background-image: url("/images/application-en_blur-loss.png");
  }

  .sign-up-4--form {
    background: $beige;
    display: inline-block;
    padding: 40px 30px;
    margin: 20px;
    margin-top: 100px;
    box-shadow: 20px 20px 20px rgba(0,0,0,.3);
    overflow: hidden;
    max-width: 30em;
  }
}

@media (min-width: $screen-md-min) {
  .sign-up-5--form {
    background: $beige;
    display: block;
    padding: 40px 30px;
    margin: 20px;
    margin-top: 60px;
    box-shadow: 3px 3px 3px rgba(0,0,0,.3);
    max-width: 30em;
    position: relative;
    left: -13.5px;

    &:before {
      border: 10px solid;
      border-color: transparent transparent $beige;
      content: "";
      left: 30px;
      margin-left: -10px;
      position: absolute;
      top: -20px;
    }

    &.right {
      margin-left: auto;
      margin-right: 20px;
      left: inherit;

      &:before {
        left: inherit;
        right: 74px;
      }
    }
  }
}

.sign-up-5--form {
  #sign-up-step-1 {
    h3 {
      margin-bottom: 0;
    }

    img {
      position: relative;
      margin-bottom: -55px;
    }

    button {
      clear: both;
    }

    div:first-of-type {
      padding-left: 70px;
    }
  }

  #sign-up-step-2 {
    position: relative;

    div:first-of-type {
      padding-right: 70px;
    }

    img {
      position: absolute;
      right: 0;
      border-radius: 50%;
      border: 1px solid #dddddd;
    }
  }
}

//@media (min-width: $screen-md-min) {
  .sign-up-6--wrp {
    height: 100%;
    background-position: top center;
    background-size: cover;
    background: #fefdfa;

    &:before {
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      height: 69px;
      background: #ff9419;
      z-index: 0;
    }
  }
  .sign-up-6--inner-wrp {
    width: $screen-md-min;
    height: 100%;
    margin: auto;
    padding: 0.1px;
    position: relative;
    z-index: 10;
    background-image: url("/images/application_blur-loss-v3.png");
    background-repeat: no-repeat;
  }

  .sign-up-22--inner-wrp {
    width: $screen-md-min;
    height: 100%;
    margin: auto;
    padding: 0.1px;
    position: relative;
    z-index: 10;
    background-image: url("/images/application_blur-loss-v22.png");
    background-repeat: no-repeat;
  }

  .sign-up-6--inner-wrp-en {
    background-image: url("/images/application-en_blur-loss.png");
  }

  .sign-up-6--form {
    background: $beige;
    display: inline-block;
    padding: 40px 30px;
    margin: 20px;
    margin-top: 100px;
    box-shadow: 20px 20px 20px rgba(0,0,0,.3);
    overflow: hidden;
    max-width: 30em;
  }

  .sign-up-7--form {
    background: $beige;
    display: inline-block;
    padding: 40px 30px;
    margin: 20px;
    margin-top: 100px;
    box-shadow: 20px 20px 20px rgba(0,0,0,.3);
    overflow: hidden;
    max-width: 30em;
    max-height: calc(100vh - 100px);
  }

  .sign-up-11--form {
    background: $beige;
    display: inline-block;
    margin: 20px;
    margin-top: 100px;
    box-shadow: 20px 20px 20px rgba(0,0,0,.3);
    overflow-y: scroll;
    max-width: 100%;
    max-height: calc(100vh - 100px);
    border-radius: 15px 15px 15px 15px;
  }

  .sign-up-12--form {
    background: $beige;
    display: inline-block;
    padding: 40px 30px;
    margin: 20px;
    margin-top: 100px;
    box-shadow: 20px 20px 20px rgba(0,0,0,.3);
    border-radius: 15px 15px 15px 15px;
    overflow: hidden;
    max-width: 30em;
  }

  .sign-up-8--wrp {
    min-height: 100%;
    background-position: top center;
    background-size: absolute;
    background: #aaaaa8;
    background-repeat: repeat;

    &:before {
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      height: 68px;
      background: #aa681e;
      z-index: 0;
    }
  }
  .sign-up-8--inner-wrp {
    width: $screen-md-min;
    margin: auto;
    padding: 0.1px;
    position: relative;
    z-index: 10;
    background-image: url("/images/application_blur-loss-v9.png");
  }
  .sign-up-8--inner-wrp-en {
    background-image: url("/images/application-en_blur-loss.png");
  }

  .sign-up-8--form {
    background: $beige;
    display: inline-block;
    padding: 40px 30px;
    margin: 20px;
    margin-top: 100px;
    box-shadow: 20px 20px 20px rgba(0,0,0,.3);
    border-radius: 15px 15px 15px 15px;
    overflow: hidden;
    max-width: 30em;
  }

  .sign-up-9--wrp {
    	//adding css New page background Desktop & Mobile 
      height: 100%;
      &:before{
        content: "";
        position: absolute;
        display: block;
        background: url(/images/signup/new-bridgepage-background-desktop-final.png) left top no-repeat;
        background-size: cover;
        width:100%;
        height:100%;
        z-index:0;
        filter: blur(1px);
      }
      &.overlay{
        &:before{
          filter: blur(1px);
          -webkit-filter: blur(1px);
        }
      }
      @media screen and (max-width:991px){
        &:before{
          background: url(/images/signup/new-bridge-page-background-mobile.jpg) left top no-repeat;
          background-size: cover;
        }
      }
      .sign-up-4--inner-wrp-en , .sign-up-6--inner-wrp{
        background:none;
        margin-top:0;
      }  
    }

    .sign-up-10--wrp {
    	//adding css New page background Desktop & Mobile 
      height: 100%;
      &:before{
        content: "";
        position: absolute;
        display: block;
        background: url(/images/signup/schichtplaner-anwendung-v1.jpg) left top no-repeat;
        background-size: cover;
        width:100%;
        height:100%;
        z-index:0;
      }
      &.overlay{
        &:before{
          filter: blur(1px);
          -webkit-filter: blur(1px);
        }
      }
      @media screen and (max-width:991px){
        &:before{
          background: url(/images/signup/schichtplaner-mobil.jpg) left top no-repeat;
          background-size: cover;
        }
      }
      .sign-up-4--inner-wrp-en , .sign-up-6--inner-wrp{
        background:none;
        margin-top:0;
      }  
    }

  
//}


//Adding  signup modal new code
.signup-form-new{
	$self: &;
	.signup-modal{
		width:100%;
		height:100%;
		position:fixed;
		left: 0;
		right:0;
		background: transparent;
		&__dialog{
			max-width:730px;
			width:85%;
			margin:auto;
			padding:30px;
			background:#fff;
			box-shadow: 0px 0px 20px #00000041;
			border-radius: 10px;
			position:relative;
			transform: translateY(-50%);
			display:none;
			top:50%;
		}
		&.show{
			background: #00000066;
			overflow-y: auto;
			.signup-modal__dialog{
				display:block;
			}
		}
		form{   
			background: transparent;
			box-shadow: none;
			max-width: 100%;
			padding: 0;
			margin: 0;
      overflow: hidden;
      max-height: unset;
		}
		.customer-review{
			background: #F5F5F5;
			border-radius: 6px;
			padding:20px;
			p{
				font-size:14px;
        margin:0;
				margin-bottom:15px;
        font-style: italic;
			}
			&__detail{
				display: flex;
				font-size:13px;
				color:#888888;
				font-weight:600;
				border-top: 1px solid #DDDDDD;
				padding-top:15px;
				img{
					width:40px;
					min-width:40px;
					height:40px;
					border-radius: 100%;
					margin-right:15px;
				}
				span{
					display:block;
					font-weight:400;
				}
			}
		}
		h4{
			font-weight:600;
			font-size:18px;
			margin-bottom:16px;
		}
		&__head{
			display:flex;
			align-items:center;
			justify-content: center;
			padding-right:50px;
		}
		.sign-up-2--heading{
			margin:0;
			color: #333333;
			font-size:42px;
			font-weight:700;
			margin-left:20px;
		}
		&__top-image{
			position:absolute;
			right:-70px;
			left:auto;
			top:-70px;
		}
		ul{
			display: flex;
			align-items: center;
			justify-content: space-between;
			list-style: none;
			margin:30px 0;
			padding:0;
			li{
				position:relative;
				background: #FEF9F3;
				border-radius: 4px;
				width: 216px;
				height: 130px;
				text-align: center;
				color: #333333;
				font-size:20px;
				line-height:1.3;
				font-weight:600;
				padding:20px 10px;
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				&:before{
					content:"";
					position:relative;
					background: url(/images/signup/check-icon.png) center center no-repeat;
					width: 34px;
					height: 26px;
					margin:auto auto 10px;
				}
				&:not(:last-child){
					margin-right:10px;
				}
			}
		}
		&__body{
			&--feilds-wrapper{
				display:flex;
				flex-wrap: wrap;
				justify-content: space-between;
				width:100%;
			}
		}
		.form-group{
			width:49%;
			margin-bottom:20px;
			text-align: left;
			p{
				margin:0;
				color:#888888;
				font-size: 12px;
				text-align: left;
				a{
					font-size: 12px;
					color:#888888;
					text-decoration: none;
					&:hover{
						color: #ff9419;
					}
				}
			}
		}
		.input--shapeless{
			border: 1px solid #CECECE;
			border-radius: 4px;
			height:42px;
			line-height:42px;
			padding:0 15px;
			color:#686664;
			margin:0;
			&::placeholder{
				color:#686664;
			}
			&:focus{
				border-color:#91908F;
			}
		}
		label{
			font-size:16px;
			font-weight:600;
			margin-bottom:8px;
			span{
				font-weight:400;
				margin-left: 3px;
			}
		}
		.button--solid--cta{
			width:100%;
			font-size:16px;
			font-weight:600;
			letter-spacing: 0;
			padding-top:0;
			padding-bottom:0;
			line-height:44px;
			height:44px;
			background:#F2A659;
			box-shadow: none;
			&:hover{
				background: #ff9419;
			}
		}
		.img-fluid{
			max-width:100%;
			height:auto;
		}
		.d-flex{
			display:flex;
			align-items:center;
		}
		@media screen and (max-width:1600px){
			&.show{
				.signup-modal__dialog{
					top:0;
				}
			}
			&__dialog{
				transform: none;
				margin:7% auto;
			}
		}
		@media screen and (max-width:991px){
      z-index: 1;
			&__top-image{
				right: -40px;
				top: -40px;
				img{
					max-width:120px;
				}
			}
		}
		@media screen and (max-width:767px){
			ul{
				flex-wrap: wrap;
				li{
					width:100%;
					height:auto;
					font-size:16px;
					flex-direction: revert;
					text-align: left;
					justify-content: start;
					padding:10px 20px;
			&:not(:last-child){
			margin-bottom:14px;
			}
					&:before{
						margin: 0;
						margin-right:14px;
					}
				}
			}
			.form-group{
				width:100%;
			}
			.sign-up-2--heading{
				font-size:32px;
			}
		&__dialog{
			margin: 10% auto;
			padding:30px 20px;
		}
		&__head{
		img{
			max-width:55px;
		}
		}
		.sign-up-2--heading{
		margin-left:10px;
		}
		}
		@media screen and (max-width:575px){
			.sign-up-2--heading{
				font-size:23px;
			}
		&__top-image{
		right: -25px;
		top: -24px;
		img{
			max-width:90px;
		}
		}
		}
	}
	.d-none{
	  display:none;
	}
	//adding css New page background Desktop & Mobile 
    height: 100%;
		&:before{
			content: "";
			position: absolute;
			display: block;
			background: url(/images/signup/new-bridgepage-background-desktop-final.png) left top no-repeat;
			background-size: cover;
			width:100%;
			height:100%;
			z-index:0;
		}
		&.overlay{
			&:before{
				filter: blur(1px);
				-webkit-filter: blur(1px);
			}
		}
		@media screen and (max-width:991px){
			&:before{
				background: url(/images/signup/new-bridge-page-background-mobile-20221102-143043.png) left top no-repeat;
				background-size: cover;
			}
		}
    .sign-up-4--inner-wrp-en , .sign-up-6--inner-wrp{
      background:none;
      margin-top:0;
    }
}



